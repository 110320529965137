export var ID = '@aliTrip';
export default {
    ID: ID,
    ACCOUNT_BIND: ID + "/ACCOUNT_BIND",
    GET_BIND_STATUS: ID + "/GET_BIND_STATUS",
    GET_SYNC_TIME: ID + "/GET_SYNC_TIME",
    GET_FLIGHT_ORDERS: ID + "/GET_FLIGHT_ORDERS",
    GET_SUB_KEYS: ID + "/GET_SUB_KEYS",
    ACCOUNT_UNBIND: ID + "/ACCOUNT_UNBIND"
};
