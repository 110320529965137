var _a;
import loadable from '@loadable/component';
import key from './key';
export default [
    {
        id: key.ID,
        reducer: function () { return import('./ali-trip.reducer'); }
    },
    {
        point: '@@components',
        namespace: key.ID,
        onload: function () { return [{ key: 'AliTripView', component: function () { return import('./ali-trip-view'); } }]; }
    },
    {
        resource: key.ID,
        value: (_a = {},
            _a['ali-trip/file-order'] = loadable(function () { return import('./file-orders'); }),
            _a)
    }
];
